<template>
  <Web>
    <div v-if="page">
      <section class="job-header">
        <div class="container">
          <div class="job-header-inner">
            <div class="job-meta">
              <h1 class="job-title">{{ page.header_title }}</h1>
              <h4 class="job-subtitle">{{ page.header_subtitle }}</h4>
              <div class="job-text">{{ page.header_text }}</div>
            </div>
            <div class="job-action">
              <template v-if="page.header_button_1">
                <b-button :href="page.header_button_1_link" variant="outline-light" size="lg" class="me-2">{{ page.header_button_1 }}</b-button>
              </template>
              <template v-else>
                <b-button href="#apply" variant="outline-light" size="lg" class="me-2">{{ $t('Apply now') }}</b-button>
              </template>
              <template v-if="page.header_button_2">
                <b-button :href="page.header_button_2_link" variant="primary" size="lg" class="me-2">{{ page.header_button_2 }}</b-button>
              </template>
            </div>
          </div>
        </div>
        <div class="job-header-bg" :style="{ backgroundImage: 'url(https://api.recruitment.page/uploads/header/' + page.header_image + ')' }"></div>
      </section>
      <section class="job-details" id="detail">
        <div class="container">
          <template v-if="page.recruiter_visible">
            <section class="job-section job-contact mb-5">
              <b-row align-v="center">
                <b-col sm="12" md="3" class="mb-2">
                  <h3 class="mb-3 h4">{{ $t('Ask a question directly') }}</h3>
                </b-col>
                <b-col sm="12" md="1" class="mb-2">
                  <img src="https://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="avatar avatar-lg rounded-circle" alt="avatar" style="max-width:80px;">
                </b-col>
                <b-col sm="12" md="4" class="mb-2">
                  <h5>
                    {{ page.recruiter_firstname }} {{ page.recruiter_lastname }}
                    <small class="lead d-block">{{ page.recruiter_title }}</small>
                  </h5>
                </b-col>
                <b-col sm="12" md="4" class="mb-2">
                  <ul class="fa-ul">
                    <template v-if="page.recruiter_email">
                      <li>
                        <a :href="`mailto:` + page.recruiter_email">
                          <font-awesome-icon icon="fa-solid fa-envelope" class="me-1" /> {{ page.recruiter_email }}
                        </a>
                      </li>
                    </template>
                    <template v-if="page.recruiter_phone">
                      <li>
                        <a :href="`tel:` + page.recruiter_phone">
                          <font-awesome-icon icon="fa-solid fa-phone" class="me-1" /> {{ page.recruiter_phone }}
                        </a>
                      </li>
                    </template>
                    <template v-if="page.recruiter_whatsapp">
                      <li>
                        <a :href="`tel:` + page.recruiter_whatsapp">
                          <font-awesome-icon icon="fa-solid fa-whatsapp" class="me-1" /> {{ page.recruiter_whatsapp }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </b-col>
              </b-row>
            </section>
          </template>
          <section class="job-content" v-html="page.content"></section>
          <template v-if="page.video">
            <section class="job-section job-video">
              <div class="video-embeded">
                {{ page.video }}
              </div>
            </section>
          </template>
          <template v-if="page.company_description">
            <section class="job-section job-company small-section text-center">
              <div class="section-title">
                <h2>{{ $t('Working at') }} {{ page.company_name }}</h2>
              </div>
              <div>
                {{ page.company_description }}
              </div>
            </section>
          </template>
        </div>
        <section class="job-interested" id="apply">
          <div class="container job-interested-inner">
            <div class="section-title text-center">
              <h2>{{ $t('Intressted?') }}</h2>
              <p>{{ $t('Fill in the form below and receive more information without any obligations.') }}</p>
            </div>
            <div>
              <template v-if="applicationError">
                <b-alert variant="danger" show class="mt-4 mb-4">
                  <h4>{{ $t('Application failed!') }}</h4>
                  {{ $t('Something went wrong while processing your application. Please try again or contact us!') }}
                </b-alert>
              </template>
              <template v-if="applicationSuccessful">
                <b-alert variant="success" show class="mt-4 mb-4">
                  <h4>{{ $t('Application received!') }}</h4>
                  {{ $t('Thank you for your interest! We will contact you as soon as possible.') }}
                </b-alert>
              </template>
              <template v-if="!applicationStart">
                <b-form @submit.stop.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                          <b-form-group class="required" :label="$t('Firstname')" label-for="firstname">
                            <b-form-input :state="validateState('firstname')" v-validate="{ required: true }" name="firstname" v-model="form.firstname" type="text"></b-form-input>
                            <b-form-invalid-feedback>{{ veeErrors.first('firstname') }}</b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-md-6 mb-3">
                            <b-form-group class="required" :label="$t('Lastname')" label-for="lastname">
                              <b-form-input :state="validateState('lastname')" v-validate="{ required: true }" name="lastname" v-model="form.lastname" type="text"></b-form-input>
                              <b-form-invalid-feedback>{{ veeErrors.first('lastname') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="mb-3">
                      <b-form-group class="required" :label="$t('City')" label-for="city">
                        <b-form-input :state="validateState('city')" v-validate="{ required: true }" name="city" v-model="form.city" type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('city') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group class="required" :label="$t('Email')" label-for="city">
                        <b-form-input :state="validateState('email')" v-validate="{ required: true, email: true }" name="email" v-model="form.email" type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('email') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group class="required" :label="$t('Phone')" label-for="phone">
                        <b-form-input :state="validateState('phone')" v-validate="{ required: true }" name="phone" v-model="form.phone" type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('phone') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group :label="$t('CV')" label-for="cv">
                        <b-form-file label="false" :state="validateState('cv')" v-validate="{ ext: ['pdf','txt','doc','docx','rtf'] }" name="cv" v-model="files.cv" class="form-control"></b-form-file>
                        <b-form-invalid-feedback>{{ veeErrors.first('cv') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group :label="$t('Remark')" label-for="remark">
                        <b-form-textarea :state="validateState('remark')" v-validate="{ min: 2 }" v-model="form.description" name="remark"></b-form-textarea>
                        <b-form-invalid-feedback>{{ veeErrors.first('remark') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group class="required">
                        <b-form-checkbox :state="validateState('terms_accepted')" v-validate="{ required: true }" name="terms_accepted" v-model="form.terms_accepted" class="accept-terms" value="1">
                          {{ $t('I agree to the terms and conditions and privacy policy.') }}
                        </b-form-checkbox>
                        <b-form-invalid-feedback>{{ veeErrors.first('terms_accepted') }}</b-form-invalid-feedback>
                      </b-form-group>
                      
                    </div>
                    <b-button type="submit" variant="primary" size="lg">{{ $t('Apply now') }}</b-button>
                </b-form>
              </template>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Web>
</template>

<script>
import Web from '@/layouts/Web';
import axios from 'axios';

export default {
  data() {
    return {
      applicationStart: false,
      applicationSuccessful: false,
      applicationError: false,
      page: [],
      errors: [],
      files: {
        cv: null,
      },
      form: {
        firstname: '',
        lastname: '',
        city: '',
        email: '',
        phone: '',
        cv: '',
        remark: '',
        terms_accepted: '',
      }
    }
  },
  components: {
    Web
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getPagedetails: function() {
      axios
        .get('https://api.recruitment.page/pages?filter=slug:' + this.$route.params.slug)
        .then(response => (this.page = response.data.pages[0]))
    },
    onSubmit() {

      this.applicationStart = true;

      let elm = this;

      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        axios
          .post('https://api.recruitment.page/applications', {
            'type':'page',
            'page':this.page,
            'form':this.form
          })
          .then(function (response) {
            if (response.data.status == 201) {
              
              axios
                .post('https://api.recruitment.page/cvs', {
                  'file': elm.files.cv,
                  'candidate': response.data.candidate.id
                }, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                  if (response.data.status == 201) {
                    elm.applicationSuccessful = true;
                  } else {
                    elm.applicationError = true;
                  }
                })

            } else {
              elm.applicationError = true;
            }
          })
      });
    },
  },
  mounted () {
    this.getPagedetails();
  },
  metaInfo () {
    let page = this.page;
    return {
      title: (page.length < 1 ? 'Loading...' : page.meta_title),
      meta: [
        { name: 'description', content: page.meta_description }
      ]
    }
  },
}
</script>

<style>
  .accept-terms label{
    margin-left:5px;
  }
</style>

<style scoped> 

  .section-title h2,
  .job-content h2,
  .job-content h3,
  .job-content h4,
  .job-content h5,
  .job-content h6{
    font-style: normal;
    font-weight: 700;
  }
  .card{
    text-decoration: none;
    margin-bottom:25px;
  }
  .card-meta{
    list-style:none;
    padding:0;
    color:rgba(0,0,0,0.5);
  }
  .card-meta li{
    display:inline-block;
    border-right:1px solid rgba(0,0,0,.125);
    padding-right: 10px;
    margin-right: 5px;
  }
  .card-image{
    background-position: 50% 50%;
    width:100%;
    height:250px;
  }
  .job-header{
    position: relative;
  }
  .job-header .container{
    position: relative;
    z-index:2;
  }
  .job-header:before{
    z-index:1;
    content:"";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
  }
  .job-header-bg{
    z-index:0;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
  @media screen and (min-width:1024px) {
    .job-header-inner{
      max-width:50%;
    }
  }
  .footer-main{
    margin-top: 0px !important;
  }
  .job-details{
    position: relative;
    z-index:1;
  }
  .job-interested{
    background-color:#f4f4f4;
    margin-top: 50px;
    padding-top:50px;
    padding-bottom:50px;
    margin-bottom:0 !important;
  }
  .job-interested form{
    text-align:left;
  }
  .job-interested-inner{
    max-width:600px;
  }
  .video-embeded iframe{
    width:100% !important;
    min-height:600px;
  }
  .privacy{
    padding:10px;
    vertical-align:middle;
    opacity:0.3;
    transition: opacity 0.2s ease-in-out;
  }
  .mb-3:hover .privacy{
    opacity:0.8;
  }
  @media screen and (max-width:768px) {
    .privacy{
      display:inline-block;
      width:70%;
    }
  }
  .job-section.small-section{
    max-width:750px;
    margin-left:auto;
    margin-right:auto;
  }
  
</style>