<template>
    <div v-if="layout">
        <b-navbar>
            <b-container fluid>
                <b-navbar-brand href="#">
                    <img :alt="layout.company_name" :src="`https://api.recruitment.page/uploads/logo/` + layout.logo" style="max-height:50px;">
                </b-navbar-brand>
            </b-container>
        </b-navbar>
        <slot :layout="layout"></slot>
        <footer class="footer-main">
            <div class="container">
                <ul class="footer-links">
                    <li>
                        <a :href="layout.terms_cookies_link" target="_blank">
                            Cookies
                        </a>
                    </li>
                    <li>
                        <a :href="layout.terms_privacy_link" target="_blank">
                            Privacy
                        </a>
                    </li>
                    <li>
                        <a :href="layout.terms_conditions_link" target="_blank">
                            Algemene Voorwaarden
                        </a>
                    </li>
                    <li>
                        <a href="https://recruitment.page/tos" target="_blank">
                            Servicevoorwaarden
                        </a>
                    </li>
                </ul>
                <ul class="footer-contact">
                    <li>
                        <a :href="`mailto:` + layout.company_email" target="_blank">
                            {{ layout.company_email }}
                        </a>
                    </li>
                    <li>
                        <a :href="`tel:`+ layout.company_phone" target="_blank">
                            {{ layout.company_phone }}
                        </a>
                    </li>
                    <li>
                        <a :href="`https://www.google.com/maps?q=` + layout.company_address_line_1" target="_blank">
                            <address>
                                {{ layout.company_address_line_1 }} {{ layout.company_address_line_2 }}
                            </address>
                        </a>
                    </li>
                </ul>
                <ul class="footer-copyright">
                    <li>Copyright © {{new Date().getFullYear()}} {{ layout.company_name }}</li>
                    <li>
                        <a href="https://recruitment.page" target="_blank">
                            Powered by Recruitment.page
                        </a>
                    </li>
                </ul>
                <ul class="footer-social">
                    <li>
                        <a :href="layout.social_linkedin" target="_blank">
                            Linkedin
                        </a>
                    </li>
                    <li>
                        <a :href="layout.social_instagram" target="_blank">
                            Instagram
                        </a>
                    </li>
                    <li>
                        <a :href="layout.social_facebook" target="_blank">
                            Facebook
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
    
    body{
        font-weight: 300;
        font-family: 'Roboto',Arial,Helvetica,Sans;
    }

    .navbar-light{
        background-color:#fff;
    }
    .jobs-header{
        background-color:#4F87E0;
        padding: 30px 20px;
        margin-bottom:50px;
    }
    .jobs-header .jobs-header-title{
        text-align:center;
        color:#fff;
        margin-bottom:25px;
    }
    @media screen and (min-width:768px) {
        .jobs-header{
            padding-top:120px;
        }
    }
    .jobs-search{
        max-width:500px;
        margin-left:auto;
        margin-right:auto;
    }
    .jobs-search button{
        border-color:#fff;
        color:#fff;
    }
    .jobs-filters{
        margin-bottom:25px;
    }
    .jobs-results{
        margin-bottom:50px;
    }


    .footer-main{
        text-align:center;
        padding:50px 0;
        background-color:#eee;
    }
    .footer-main ul{
        list-style:none;
        padding:0;
        margin-bottom:5px;
    }
    .footer-main ul li{
        display:inline-block;
    }
    .footer-main ul li,
    .footer-main ul li a{
        color:#666;
        font-size:12px;
        text-decoration:none;
    }
    .footer-main ul li:not(:last-child){
        border-right: 1px solid #d8d8d8;
        padding-right:5px;
        margin-right:5px;
    }

    .job-header{
        background-color:#4F87E0;
        padding: 30vh 20px;
        margin-bottom:50px;
    }
    .job-meta{
        margin-bottom:25px;
    }
    .job-title{
        color:#fff;
    }
    .job-subtitle{
        color:#fff;
    }
    .job-action .btn-outline-primary{
        border-color:#fff;
        color:#fff;
    }
    .job-action .btn-primary{
        background-color:#ff6161;
        border:1px solid #ff6161;
        color:#fff;
    }

    .job-requirements{
        background-color:#fff;
        padding:25px;
        margin-top:-150px;
    }

    .job-contact{
        background-color:#F8F8F8;
        padding:25px;
        border-radius:15px;
    }

    .job-details section{
        margin-bottom:100px;
    }

    .job-interested{
        text-align:center;
    }

    .form-group{
        margin-bottom:15px;
    }
    .form-group label{
        margin-bottom:5px;
    }
    .custom-file-label{
        display:none;
    }

    ul.fa-ul{
        margin:0;
        list-style:none;
        list-style-position:inside;
    }
    ul.fa-ul li{
        margin-bottom:5px;
    }
    ul.fa-ul li i{
        margin-right:5px;
        color: var(--primary);
    }
    ul.fa-ul li a{
        text-decoration:none;
    }
</style>
<style>
    .form-group label{
        margin-bottom:0.2em;
    }
    .form-group.required label:after{
        content: " *";
        font-size:80%;
        color:red;
    }
    .form-group.required:hover label:after{
        content: " (required)";
        font-size:80%;
        color:red;
    }
    .custom-file-label{
        display:none;
    }
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
        layout: null,
    }
  },
  components: {},
  methods: {
    getLayout: function() {
      axios
        .get('https://api.recruitment.page/websites?filter=domain:' + window.location.hostname)
        .then(response => (this.layout = response.data.websites[0]))
    }
  },
  mounted () {
    this.getLayout();
  },
}
</script>